import React from 'react'
import {Provider} from 'react-redux'
import Router from './router'
import store from './store'
import './style/reset.scss';
import './style/index.scss';
import ActivityIndicator from './components/ActivityIndicator'
import Header from './components/Header'

function App() {
    return (<Provider store={store}>
        <Header/>
        <Router/>
        <ActivityIndicator/>
    </Provider>);
}

export default App;
