import React from 'react'
import {connect} from 'react-redux'
import {ActivityIndicator} from 'antd-mobile'


const AppActivityIndicator = function ({indicator = {}}:any) {
    return <ActivityIndicator animating={indicator.animating} toast={indicator.toast} text={indicator.text}/>
}

export default connect(({activityIndicator}:any) => ({
    indicator: activityIndicator.indicator
}))(AppActivityIndicator)
