const initialState = {
    navTitle: '900游',
    headerShown: true,
    onLeftClick: null//没有 或者返回为false就默认跳转 如果为true不处理
};

export default function commonReducer(state = initialState, action: any) {
    switch (action.type) {
        //改变title
        case 'CHANGE_NAV_TITLE':
            return {
                ...state,
                navTitle: action.data
            }
            break;
        //改变Header是否显示
        case 'CHANGE_HEADER_SHOWN':
            return {
                ...state,
                headerShown: action.data
            }
            break;
        default:
            return state;
    }
}
