export default [
    {
        path: '/priceQingdao',
        component: () => import('../pages/priceQingdao'),
        meta: {
            title: "青岛市计价方案"
        }
    },
    {
        path: '/priceNingbo',
        component: () => import('../pages/priceNingbo'),
        meta: {
            title: "宁波市计价方案"
        }
    },
    {
        path: '/priceJiaXing',
        component: () => import('../pages/priceJiaXing'),
        meta: {
            title: "嘉兴市计价方案"
        }
    },
    {
        path: '/priceHangzhou',
        component: () => import('../pages/priceHangzhou'),
        meta: {
            title: "杭州市计价方案"
        }
    },
    {
        path: '/itinerary',
        component: () => import('../pages/itinerary')
    },
    {
        path: '/download',
        component: () => import('../pages/download')
    },
    {
        path: '/home',
        component: () => import('../pages/home')
    },
    {
        path: '/privacyPolicy',
        component: () => import('../pages/privacyPolicy'),
        meta: {
            title: "900游隐私政策"
        }
    },
    {
        path: '/servicePact',
        component: () => import('../pages/servicePact')
    },
    {
        path: '/invoice/:id',
        component: () => import('../pages/invoice'),
        meta: {
            title: '发票信息'
        }
    },
    {
        path: '/priceJiNan',
        component: () => import('../pages/priceJiNan'),
        meta: {
            title: '济南运价公式'
        }
    },
    {
        path: '/priceChengdu',
        component: () => import('../pages/priceChengdu'),
        meta: {
            title: '成都运价公式'
        }
    },
    {
        path: '/bookingNotice',
        component: () => import('../pages/bookingNotice'),
        meta: {
            title: '预订须知'
        }
    },
    {
        path: '*',
        component: () => import('../pages/download')
    }
]
