import React from 'react'
import {useLocation} from 'react-router-dom';
import loadable from "@loadable/component";
import Loading from './loading'
import {
    HashRouter as Router,
    Route,
    Switch
} from "react-router-dom";


import routes from './routes';


export default function () {
    return <Router>
        <Switch>
            {
                routes.map(({path, component}, idx) => (
                    <Route path={path}
                           key={idx}
                           component={loadable(component,
                               {
                                   fallback: <Loading/>
                               })
                           }/>
                ))
            }
        </Switch>
    </Router>
}

export const useQuery = function () {
    return new URLSearchParams(useLocation().search);
}
