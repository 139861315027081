const ls = 'localStorage' in window && window.localStorage;

const setObject = function (name: string, data: object) {
    if (!ls || !name)
        return false;
    if (data) {
        let result: string = JSON.stringify(data);
        ls.setItem(name, result);
    }

}
const getObject = function (name: string): object {
    if (!ls || !name)
        return {};
    try {
        const result: any = ls.getItem(name);
        if (result)
            return JSON.parse(result);
        else return {}
    } catch (e) {
        return {}
    }

}
const set = function (name: string, data: string) {
    if (!ls || !name)
        return false;
    ls.setItem(name, data);

}
const get = function (name: string) {
    if (!ls || !name)
        return false;
    return ls.getItem(name);
}
const remove = (name: string) => {
    ls && ls.removeItem(name);
}
const clearAll = () => {
    ls && ls.clear();

}
export default {
    setObject: setObject,
    getObject: getObject,
    set: set,
    get: get,
    removeItem: remove,
    clearAll: clearAll
}
