const initialState = {
    orderInfo: {},
    orderItinerary: {}//行程分享订单详细
};

export default function commonReducer(state = initialState, action: any) {
    switch (action.type) {
        //获取到配置中心数据 改变本地默认数据
        case 'CHANGE_ORDER_INFO':
            return {
                ...state,
                orderInfo: action.data
            }
            break;
        //改变行程分享订单详细
        case 'CHANGE_ORDER_ITINERARY_INFO':
            return {
                ...state,
                orderItinerary: action.data
            }
            break;
        default:
            return state;
    }
}
