import {createStore, combineReducers} from 'redux'
import orderDetail from './reducer/orderDetailReducer'
import activityIndicator from './reducer/activityIndicatorReducer'
import user from './reducer/userReducer'
import common from './reducer/commonReducer'

const reducer = combineReducers({
    orderDetail,
    activityIndicator,
    user,
    common
})

export default createStore(reducer)
