import React from 'react'
import {NavBar} from 'antd-mobile';
import {connect} from "react-redux";
import {useHistory} from 'react-router-dom';

interface propsTypes {
    navTitle: string,
    headerShown: boolean
    onLeftClick?: Function
}

const Header = function ({navTitle, headerShown, onLeftClick}: propsTypes) {
    const history = useHistory();

    const handleLeftClick = () => {
        const canBack = onLeftClick && onLeftClick()
        if (!canBack)
            history.goBack();
    }

    return headerShown ? <NavBar mode="light" onLeftClick={handleLeftClick}>
        {navTitle}
    </NavBar> : null
}

export default connect(({common}: any) => ({
    navTitle: common.navTitle,
    headerShown: common.headerShown,
}))(Header)
