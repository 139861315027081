import localStorage from '../../script/localStorage'
import {localUserInfo} from '../../constants/localStorageName'

const initialState = {
    contacts: [],//常用联系人
    userInfo: {}
};

export default function commonReducer(state = initialState, action:any) {
    switch (action.type) {
        //获取到配置中心数据 改变本地默认数据
        case 'CHANGE_USER_TOP_CONTACTS':
            return {
                ...state,
                contacts: action.data
            }
            break;
        //改变用户信息
        case 'CHANGE_USER_INFO':
            localStorage.setObject(localUserInfo, action.data);
            return {
                ...state,
                userInfo: action.data
            }
            break;
        default:
            return state;
    }
}
