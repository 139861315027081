export interface Indicator {
    animating: boolean,
    toast: boolean,
    text: string
}

const indicator: Indicator = {
    animating: false,
    toast: false,
    text: ''
}

const initialState = {
    indicator: {...indicator}
};

export default function commonReducer(state = initialState, action:any) {
    switch (action.type) {
        //获取到配置中心数据 改变本地默认数据
        case 'CHANGE_ACTIVITY_INDICATOR':
            return {
                ...state,
                indicator: {
                    ...indicator,
                    ...action.data
                }
            }
            break;
        default:
            return state;
    }
}
